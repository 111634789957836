.cssbuttons-io {
    --color: #00A97F;
    padding: 0.8em 1.7em;
    background-color: transparent;
    border-radius: .3em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: 400;
    font-size: 17px;
    border: 1px solid;
    text-transform: uppercase;
    color: var(--color);
    z-index: 1;
   }
   
   .cssbuttons-io::before, .cssbuttons-io::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color);
    transition: 1s ease;
   }
   
   .cssbuttons-io::before {
    top: -1em;
    left: -1em;
   }
   
   .cssbuttons-io::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
   }
   
   .cssbuttons-io:hover::before, .cssbuttons-io:hover::after {
    height: 410px;
    width: 410px;
   }
   
   .cssbuttons-io:hover {
    color: rgb(10, 25, 30);
   }
   
   .cssbuttons-io:active {
    filter: brightness(.8);
   }






   .cssbuttons-emp {
    --color: #00A97F;
    padding: 0.8em 1.7em;
    background-color: transparent;
    border-radius: .3em;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .5s;
    font-weight: 400;
    font-size: 17px;
    border: 1px solid;
    text-transform: uppercase;
    color: var(--color);
    z-index: 1;
   }
   
   .cssbuttons-emp::before, .cssbuttons-emp::after {
    content: '';
    display: block;
    width: 50px;
    height: 50px;
    transform: translate(-50%, -50%);
    position: absolute;
    border-radius: 50%;
    z-index: -1;
    background-color: var(--color);
    transition: 1s ease;
   }
   
   .cssbuttons-emp::before {
    top: -1em;
    left: -1em;
   }
   
   .cssbuttons-emp::after {
    left: calc(100% + 1em);
    top: calc(100% + 1em);
   }
   
   .cssbuttons-emp:hover::before, .cssbuttons-emp:hover::after {
    height: 410px;
    width: 410px;
   }
   
   .cssbuttons-emp:hover {
    color: rgb(10, 25, 30);
   }
   
   .cssbuttons-emp:active {
    filter: brightness(.8);
   }
   