@media only screen and (max-width: 600px) {
    .navs{
        max-width:'100px';
        white-space: nowrap;
        display:block!important;
        flex-wrap: nowrap;
        max-width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
      
        -webkit-overflow-scrolling: touch , 
       
    }
    
    .navs li {
        display: inline-block;
      }

    .mobilicon{
        flex-direction: column;
    }
}
@media only screen and (min-width: 600px) {
    .navs{
        max-width:'200px';
        white-space: nowrap;
        display:block!important;
        flex-wrap: nowrap;
        max-width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        -webkit-overflow-scrolling: touch
       
    }
    .navs li {
        display: inline-block;
      }
    .mobilicon{
        flex-direction: row;
        height: 50%;
    }
    /* [data-v-29dcb1c6]:not(:last-child) */
    .mobile .melement{
        margin-right: 2px;
        padding: 1px;
        
        border-right: 1px solid hsla(0,3%,74.9%,20);
        
        
    }
    .mobile .melement:last-child {  border-right:0px; border-bottom: none; }
   
}