@media only screen and (max-width: 990px) {
    .colorsforheader{
        color:white;
        
       
    }
    .colorsforheader:hover{
        color:white;
        
       
    }
}
@media only screen and (max-width: 990px) {
    .flex-column-sm {
        flex-direction: column !important;
        align-items: start !important;
      }
}