*, *:before, *:after{
    margin: 0;
    padding: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing:border-box;
    
    box-sizing: border-box;
  }
  .a:hover {
    
  
    box-shadow: 1px 8px 20px grey;
    transition:  box-shadow 600s ease-in;
    
   }
.main-title{
    color: #2d2d2d;
    text-align: center;
    text-transform: capitalize;
    padding: 0.7em 0;
  }
  .contentss {
    position: relative;
    width: 90%;
    max-width: 400px;
    margin: auto;
    overflow: hidden;
  }
  
  .contentss .contentss-overlay {
    background: rgba(0,0,0,0.7);
    border-radius: 8px;
    position: absolute;
    height: 99%;
    width: 100%;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    -webkit-transition: all 4s ease-in-out 0s;
    -moz-transition: all 4s ease-in-out 0s;
    transition: all 4s ease-in-out 0s;
   
  }
  
  .contentss:hover .contentss-overlay{
    opacity: 1;
    transition: 3s ease;
  }
  
  .contentss-image{
    
    width: 100%;
    max-height: 195px;
  }
  
  .contentss-details {
    
    position: absolute;
    text-align: center;
    padding-left: 1em;
    padding-right: 1em;
    width: 100%;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  
  }
  
  .contentss:hover .contentss-details{
    top: 50%;
    left: 50%;
    transition: 3s ease;
    opacity: 1;
    transition: 1.2s ease !important;
  }
  
  .contentss-details h3{
    color: #fff;
    font-weight: 500;
    letter-spacing: 0.15em;
    margin-bottom: 0.5em;
    text-transform: uppercase;
  }
  
  .contentss-details p{
    color: #fff;
    font-size: 0.8em;
  }
  
  .fadeIn-bottom{
    top: 80%;
  }
  
  .fadeIn-top{
    top: 20%;
  }
  
  .fadeIn-left{
    left: 20%;
  }
  
  .fadeIn-right{
    left: 80%;
  }
  