//default button
.btn-new {
    color: #fff;
    cursor: pointer;
    // display: block;
    font-weight: 400;
    line-height: 45px;
   
    max-width: 460px; 
    position: relative;
    text-decoration: none;
    // text-transform: uppercase;
    width: 100%; 
    
    @media(min-width: 400px) {
      display: inline-block;
      margin-right: 2.5em;
    
      &:nth-of-type(even) {
        margin-right: 0;
      }
    }
    
    @media(min-width: 600px) {
        
      
    
  //     &:nth-of-type(even) {
  //       margin-right: 2.5em;
  //     }
      
  //     &:nth-of-type(5) {
  //       margin-right: 0;
  //     }
      
    }
    
    &:hover { text-decoration: none; }
    
  }

.btn-hov {
    border: 1px solid;
    overflow: hidden;
    position: relative;
    
    span {
      z-index: 20;
    }
    
    &:after {
      background: #fff;
      content: "";
      height: 155px;
      left: -75px;
      opacity: .2;
      position: absolute;
      top: -50px;
      transform: rotate(35deg);
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
      width: 50px;
      z-index: -10;
    }
  }
  
  .btn-hov:hover {
    
    &:after {
      left: 120%;
      transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    }
  }